import React, { useState, useEffect } from 'react';
import axios from 'axios';
import customLogo from "../Component/mainlogo.png";
import { useLocation, useNavigate } from 'react-router-dom';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit";

const API_BASE_URL = "https://recruitmentapi.iykons.com";

const imageStyle = {
    display: "block",
    margin: "0 auto",
    width: "100px",
    height: "auto",
};

const imageStyle1 = {
    display: "block",
    margin: "0 auto",
    width: "60%",
    height: "auto",
};

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tokenFromUrl = params.get('token');
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tokenFromUrl = params.get('token');
        
        if (tokenFromUrl) {
            setMessage(''); // Clear any previous error message
        } else {
            setMessage('Invalid or missing token');
        }
    }, [location.search]);

    const handleResetPassword = async () => {
        if (!email) {
            setMessage('Email is required.');
            return;
        }
        if (newPassword.length < 8) {
            setMessage('Password must be at least 8 characters long.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/Account/reset-password`,
                { token:tokenFromUrl,email, newPassword }
            );

            if (response && response.data) {
                setMessage('Password has been reset successfully');
                setTimeout(() => navigate('/'), 3000);
            } else {
                setMessage('Reset Unsuccessful. Please try again.');
            }
        } catch (error) {
            console.error('Reset password error:', error);
            if (error.response && error.response.data) {
                setMessage('Error: ' + error.response.data.message);
            } else {
                setMessage('An error occurred while making the request');
            }
        
        
        } finally {
            setIsLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <div className="login-page">
            <MDBContainer className="my-5">
                <MDBCard>
                    <MDBRow className="g-0">
                        <MDBCol md="6">
                            <img src={customLogo} alt="Custom Logo" style={imageStyle} />
                            <div>
                                <h2
                                    className="h2"
                                    style={{
                                        color: "rgb(2, 83, 204)",
                                        display: "block",
                                        textAlign: "center",
                                        textDecoration: "underline",
                                    }}
                                >
                                    Begin Your Journey With{" "}
                                    <span className="sp" style={{ fontWeight: "bold" }}>
                                        IYKONS
                                    </span>
                                </h2>
                                <h7
                                    className="h3"
                                    style={{
                                        color: "rgb(2, 83, 204)",
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    Begin your business with the right assistance.
                                </h7>
                            </div>
                            <MDBCardImage
                                src={process.env.PUBLIC_URL + "/login.png"}
                                alt="login form"
                                style={imageStyle1}
                            />
                        </MDBCol>

                        <MDBCol md="6" style={{ backgroundColor: "rgb(2, 83, 204)" }}>
                            <MDBCardBody className="d-flex flex-column">
                                <div className="d-flex flex-row mt-2">
                                    <span className="h1 fw-bold mb-0" style={{ color: "white" }}>
                                        Reset Your Password
                                    </span>
                                </div>
                                <div style={{ marginTop: '25px' }}>
                                    <div className="form-group">
                                        <label style={{ color: "white" }}>Email</label>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            className="form-control my-2"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ position: "relative" }}>
                                        <label style={{ color: "white" }}>New Password</label>
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            placeholder="New Password"
                                            value={newPassword}
                                            className="form-control my-2"
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            style={{ paddingRight: "40px" }}
                                        />
                                        <span
                                            className="input-group-text"
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: "10px",
                                                top: "70%",
                                                transform: "translateY(-50%)",
                                                zIndex: 2,
                                                backgroundColor: "transparent",
                                                border: "none",
                                            }}
                                        >
                                            <i
                                                className={passwordVisible ? "fas fa-eye" : "fas fa-eye-slash"}
                                            ></i>
                                        </span>
                                    </div>
                                    <div className="form-group" style={{ position: "relative" }}>
                                        <label style={{ color: "white" }}>Confirm Password</label>
                                        <input
                                            type={confirmPasswordVisible ? "text" : "password"}
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            className="form-control my-2"
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            style={{ paddingRight: "40px" }}
                                        />
                                        <span
                                            className="input-group-text"
                                            onClick={toggleConfirmPasswordVisibility}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: "10px",
                                                top: "70%",
                                                transform: "translateY(-50%)",
                                                zIndex: 2,
                                                backgroundColor: "transparent",
                                                border: "none",
                                            }}
                                        >
                                            <i
                                                className={confirmPasswordVisible ? "fas fa-eye" : "fas fa-eye-slash"}
                                            ></i>
                                        </span>
                                    </div>
                                </div>
                                <button className="btn btn-primary mt-4" onClick={handleResetPassword} disabled={isLoading}>
                                    {isLoading ? 'Resetting...' : 'Reset Password'}
                                </button>
                                <div
                                    className="message mt-2"
                                    style={{
                                        color: message.toLowerCase().includes('error') || message.toLowerCase().includes('invalid') || message.toLowerCase().includes('required') || message.toLowerCase().includes('match') ? 'red' : 'white',
                                        fontWeight: message.toLowerCase().includes('error') || message.toLowerCase().includes('invalid') || message.toLowerCase().includes('required') || message.toLowerCase().includes('match') ? 'bold' : 'normal',
                                    }}
                                >
                                    {message}
                                </div>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </div>
    );
}

export default ResetPassword;
