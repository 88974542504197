import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import customLogo from "../mainlogo.png";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import { useAuth } from "../AuthProvider";
function AdminLogin() {
  const [username, setUsername] = useState("info123@iykons.com");
  const [password, setPassword] = useState("Iykons@123");
  const navigate = useNavigate();
  const{Authlogin,logout}=useAuth()
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  // In AdminLogin.js
 
  const handleLogin = () => {
    const adminUsername = process.env.REACT_APP_ADMIN_USERNAME;
    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    if (username === adminUsername && password === adminPassword) {
      Authlogin(); // This should set isAuthenticated to true
      navigate("/admin/dashboard");
    } else {
      setError("Invalid username or password. Please try again.");
    }
  };
  return (
    <div className="login-page">
      <MDBContainer className="my-5">
        <MDBCard>
          <MDBRow className="g-0">
            <MDBCol md="6" className="mx-auto">
              <img src={customLogo} alt="Custom Logo" style={{ display: "block", margin: "0 auto", width: "100px", height: "auto" }} />
              <MDBCardImage src={process.env.PUBLIC_URL + "/login.png"} alt="login form" style={{ display: "block", margin: "0 auto", width: "80%", height: "auto" }} />
            </MDBCol>

            <MDBCol md="6" style={{ backgroundColor: "#105CAA" }} className="mx-auto">
              <MDBCardBody className="d-flex flex-column">
                <div className="d-flex flex-row mt-2">
                  <span className="h1 fw-bold mb-0" style={{ color: "white" }}>Admin Sign In</span>
                </div>

                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: "1px", color: "white" }}>
                  Welcome to IYKONS Admin
                </h5>

                <label style={{ color: "white" }}>Email</label>
                <MDBInput
                  wrapperClass="mb-4"
                  id="formControlLg"
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={{ color: "#105CAA" }}
                />

                <label style={{ color: "white" }}>Password</label>
                <div className="input-group mb-4" style={{ position: "relative", display: "flex", alignItems: "center" }}>
                  <input
                    className="form-control"
                    placeholder="Password"
                    id="formControlLg"
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ color: "#105CAA", paddingRight: "40px", borderRadius: "5px", zIndex: 1 }}
                  />
                  <span
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer", zIndex: 2 }}
                  >
                    <i className={passwordVisible ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                  </span>
                </div>

                <button className="btn btn-primary" onClick={handleLogin}>
                  Login
                </button>

                {error && <div style={{ color: "red" }}>{error}</div>}
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default AdminLogin;
